import React, { useState } from "react";
import contactIntroStyle from "./contactIntroStyle.module.scss";
import axios from "axios";
import { toast } from "react-toastify";
import fileAdd from "../../../Assets/GetSolar/fileAdd.svg";
import fileClose from "../../../Assets/GetSolar/fileClose.svg";

let axiosConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};

let axiosImageConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const ContactIntro = () => {
  const [activeButton, setActiveButton] = useState(0);
  const [billPic1, setBillPic1] = useState(null);
  const [billPic2, setBillPic2] = useState(null);
  const [imageOneValue, setImageOneValue] = useState({});
  const [imageTwoValue, setImageTwoValue] = useState({});
  const [message, setMessage] = useState();
  const [file1Error, setFile1Error] = useState(false);
  const [file2Error, setFile2Error] = useState(false);
  const [formOneData, setFormOneData] = useState({
    name: "",
    mail: "",
    contact: "",
    organisation: "",
    location: "",
    message: "",
  });
  const [formTwoData, setFormTwoData] = useState({
    solutionType: "",
    userName: "",
    contact: "",
    location: "",
    consumerType: "",
    // load: "",
    // consumptionPerMonth: "",
    // consumptionPerDay: "",
    consumerNumber: "",
    buNumber: "",
    bill: "",
    charges: "",
    googleLocation: "",
    typeOfArea: "",
  });

  const [btnDisabled, setBtnDisabled] = useState();

  const handleClick = (num) => {
    setActiveButton(num);
  };

  const handleOneChange = (e) => {
    setFormOneData({ ...formOneData, [e.target.name]: e.target.value });
  };

  const handleTwoChange = (e) => {
    setFormTwoData({ ...formTwoData, [e.target.name]: e.target.value });
  };

  // for file input begins

  const handleFile1Selection = (event, setImage) => {
    const file1 = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
      setImageOneValue((prevState) => ({
        ...prevState,
        electricityBillFront: file1,
      }));
    };

    reader.readAsDataURL(file1);

    setFile1Error(false);
  };

  const handleFile2Selection = (event, setImage) => {
    const file2 = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
      setImageTwoValue((prevState) => ({
        ...prevState,
        electricityBillBack: file2,
      }));
    };

    reader.readAsDataURL(file2);

    setFile2Error(false);
  };

  const handleFileRemoval = (setImage) => {
    setImage(null);
  };

  const handleOneSubmit = (e) => {
    e.preventDefault();

    setBtnDisabled(true);
    console.log(formOneData);
    var finalOneData = {
      to: "vasupokhriyal123@gmail.com",
      text: "here i am",
      ...formOneData,
      html: `<html> name: ${formOneData.name}<br/>, mail: ${formOneData.mail}<br/>, contact: ${formOneData.contact}<br/>, organisation: ${formOneData.organisation}<br/>,location: ${formOneData.location}<br/>, message: ${formOneData.message}</html>`,
      tabType: "1",
    };

    axios
      .post(
        "https://www.emailserver.northernlightenergy.in/api/v1/nle/send_email_contactus",
        finalOneData,
        axiosConfig
      )
      .then((res) => {
        setMessage("Contact Request Sent");
        toast.success("Thank you for contacting us!");
        setFormOneData({
          name: "",
          userMail: "",
          contact: "",
          organisation: "",
          location: "",
          message: "",
        });
        setTimeout(() => {
          setMessage();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message + "Try again after some time");
        setMessage(err.message + "Try again after some time");
      });
    setBtnDisabled(false);
  };

  const handleTwoSubmit = (url) => {
    console.log("data", billPic1, billPic2);

    if (!billPic1) {
      setFile1Error(true);
      console.log("file1Error", file1Error);
      return;
    } else if (!billPic2) {
      setFile2Error(true);
      console.log("file2Error", file2Error);
      return;
    }

    setBtnDisabled(true);
    console.log(formTwoData);
    var finalTwoData = {
      to: "vasupokhriyal123@gmail.com",
      text: "here i am",
      // ...formTwoData,
      html: `<html>solutionType: ${formTwoData.solutionType}<br/>,${formTwoData.financingType}<br/>  ,userName: ${formTwoData.userName}<br/>,contact: ${formTwoData.contact}<br/>,location: ${formTwoData.location}<br/>,consumerType: ${formTwoData.consumerType}<br/>,consumerNumber: ${formTwoData.consumerNumber}<br/>, buNumber: ${formTwoData.buNumber}<br/>,electricityBillFront: ${url[0]}<br/>, electricityBillBack: ${url[1]}<br/>, googleLocation: ${formTwoData.googleLocation}<br/>,typeOfArea: ${formTwoData.typeOfArea}</html>`,
      tabType: "2",
    };

    axios
      .post(
        "https://www.emailserver.northernlightenergy.in/api/v1/nle/send_email_contactus",
        finalTwoData,
        axiosConfig
      )
      .then((res) => {
        setMessage("Contact Request Sent");
        toast.success("Thank you for contacting us!");
        setFormTwoData({
          solutionType: "",
          financingType: "",
          userName: "",
          contact: "",
          location: "",
          consumerType: "",
          consumerNumber: "",
          buNumber: "",

          // load: "",
          // consumptionPerMonth: "",
          // consumptionPerDay: "",
          electricityBillFront: "",
          electricityBillBack: "",
          charges: "",
          googleLocation: "",
          typeOfArea: "",
        });
        setTimeout(() => {
          setMessage();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message + "Try again after some time");
        setMessage(err.message + "Try again after some time");
      });
    setBtnDisabled(false);
    setBillPic1(null);
    setBillPic2(null);
  };

  const handleFileUpload = (e) => {
    e.preventDefault();

    let imageFormData = new FormData();

    let billImages = [
      imageOneValue.electricityBillFront,
      imageTwoValue.electricityBillBack,
    ];

    // begin

    billImages.forEach((file, index) => {
      imageFormData.append("file", file);
    });

    // ends

    axios
      .post(
        "https://ec2-43-204-109-132.ap-south-1.compute.amazonaws.com/api/v1/upload",
        imageFormData,
        axiosImageConfig
      )
      .then((res) => {
        console.log("response", res);
        let picOneUrl = res?.data.data;

        handleTwoSubmit(picOneUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className={`container-fluid ${contactIntroStyle.contactContainer}`}>
        <div className={`row ${contactIntroStyle.contactRow}`}>
          <div
            className={`col-12 col-sm-12 col-md-6 col-lg-8 ${contactIntroStyle.contactLeft}`}
          >
            <p>
              connect with us and take
              <br />
              a step forward towards a <br />
              <span style={{ color: "rgb(127, 171, 85)" }}>
                carbon - neutral
              </span>
              <span style={{ color: "purple" }}> world</span>
            </p>
          </div>

          <div
            className={`col-12 col-sm-12 col-md-6 col-lg-4 ${contactIntroStyle.contactRight}`}
          >
            <div className={contactIntroStyle.formContainer}>
              <div className="row">
                <div className={`col-6 ${contactIntroStyle.formButtonLeft}`}>
                  <button
                    className={
                      activeButton === 0
                        ? contactIntroStyle.clickedButton
                        : contactIntroStyle.unClickedButton
                    }
                    onClick={() => handleClick(0)}
                  >
                    General <br /> Enquiry
                  </button>
                </div>
                {/* // i was here till th */}

                <div className={`col-6 ${contactIntroStyle.formButtonRight}`}>
                  <button
                    className={
                      activeButton === 1
                        ? contactIntroStyle.clickedButton
                        : contactIntroStyle.unClickedButton
                    }
                    onClick={() => handleClick(1)}
                  >
                    Solution <br /> Specific
                  </button>
                </div>
              </div>

              {/* name */}
              {activeButton === 0 ? (
                <>
                  <form onSubmit={handleOneSubmit}>
                    <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="Name"
                      name="name"
                      value={formOneData?.name}
                      onChange={handleOneChange}
                    />

                    <input
                      required
                      type="email"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="Email"
                      name="mail"
                      value={formOneData?.mail}
                      onChange={handleOneChange}
                    />

                    <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="Phone No."
                      name="contact"
                      value={formOneData?.contact}
                      onChange={handleOneChange}
                    />
                    <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="Organization"
                      name="organisation"
                      value={formOneData?.organisation}
                      onChange={handleOneChange}
                    />

                    <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="Location"
                      name="location"
                      value={formOneData?.location}
                      onChange={handleOneChange}
                    />
                    <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="Type Message"
                      name="message"
                      value={formOneData?.message}
                      onChange={handleOneChange}
                    />

                    <div className="row">
                      <div className="col-12">
                        <button
                          disabled={btnDisabled}
                          className={contactIntroStyle.submitButton}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <form onSubmit={handleFileUpload}>
                    <select
                      name="solutionType"
                      onChange={handleTwoChange}
                      value={formTwoData?.solutionType}
                      className={`form-select border-0 border-bottom ${contactIntroStyle.formFields}`}
                      required
                    >
                      <option value="">Solution Type</option>
                      <option value="ROOFTOP PV">ROOFTOP PV</option>
                      <option value="Micro Grid (ESS) - R.T.C.">Micro Grid (ESS) - R.T.C.</option>
                      <option value="OPEN ACCESS SOLAR">OPEN ACCESS SOLAR</option>
                      <option value="E.V. CHARGING">E.V. CHARGING</option>
                      <option value="DECENTRALISED HYDROGEN SOLAR">DECENTRALISED HYDROGEN SOLAR</option>
                      <option value="B.I.P.V.">B.I.P.V.</option>
                      <option value="B.M.S. - AUTOMATION FOR UTILITY EFFICIENCY">B.M.S. - AUTOMATION FOR UTILITY EFFICIENCY</option>
                    </select>

                    <select
                      name="Financing Type"
                      onChange={handleTwoChange}
                      value={formTwoData?.financingType}
                      className={`form-select border-0 border-bottom ${contactIntroStyle.formFields}`}
                      required
                    >
                      <option value="">Financing Type</option>
                      <option value="Industrial">PPA</option>
                      <option value="Residential">SRA</option>
                      <option value="Commercial">CRex</option>
                      <option value="dataCentre">Deffered Payment</option>
                      <option value="IndustrialPark">None</option>
                    </select>

                    <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom mt-0 ${contactIntroStyle.formFields}`}
                      placeholder="Name"
                      name="userName"
                      value={formTwoData?.userName}
                      onChange={handleTwoChange}
                    />

                    <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="Contact"
                      name="contact"
                      value={formTwoData?.contact}
                      onChange={handleTwoChange}
                    />

                    <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="Location"
                      name="location"
                      value={formTwoData?.location}
                      onChange={handleTwoChange}
                    />

                    <select
                      name="consumerType"
                      onChange={handleTwoChange}
                      value={formTwoData?.consumerType}
                      className={`form-select border-0 border-bottom ${contactIntroStyle.formFields}`}
                      required
                    >
                      <option value="">Consumer Type</option>
                      <option value="Commercial">Commercial</option>
                      <option value="Industrial">Industrial</option>
                      <option value="Residential">Residential</option>
                    </select>

                    {/* <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="Sanctioned Load (kW)"
                      name="load"
                      value={formTwoData?.load}
                      onChange={handleTwoChange}
                    />

                    <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="Avg. Consumption per Month (kWh)"
                      name="consumptionPerMonth"
                      value={formTwoData?.consumptionPerMonth}
                      onChange={handleTwoChange}
                    />

                    <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="% of Day Consumption(6am to 6 pm)(kWh)"
                      name="consumptionPerDay"
                      value={formTwoData?.consumptionPerDay}
                      onChange={handleTwoChange}
                    />

                    <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="Avg Bill per Month (Inr)"
                      name="bill"
                      value={formTwoData?.bill}
                      onChange={handleTwoChange}
                    />

                    <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="Demand / Fixed Charges (Inr)"
                      name="charges"
                      value={formTwoData?.charges}
                      onChange={handleTwoChange}
                    /> */}

                    <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="Consumer Number"
                      name="consumerNumber"
                      value={formTwoData?.consumerNumber}
                      onChange={handleTwoChange}
                    />

                    <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="Bu Number"
                      name="buNumber"
                      value={formTwoData?.buNumber}
                      onChange={handleTwoChange}
                    />

                    {/* the file input begins */}

                    <label
                      for="formFileMultiple"
                      className={`form-label ${contactIntroStyle.fileField} `}
                    >
                      Photo of Electricity Bill (front and back)
                    </label>

                    <input
                      type="file"
                      id="fileInput1"
                      style={{ display: "none" }}
                      onChange={(event) => {
                        handleFile1Selection(event, setBillPic1);
                        // setBillPic1(event.target.files);
                      }}
                      // className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      name="electricityBillFront"
                      value={formTwoData?.electricityBillFront}
                    />

                    <input
                      type="file"
                      id="fileInput2"
                      style={{ display: "none" }}
                      onChange={(event) => {
                        handleFile2Selection(event, setBillPic2);
                        // setBillPic2(event.target.files);
                      }}
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      name="electricityBillBack"
                      value={formTwoData?.electricityBillBack}
                    />

                    <div className={contactIntroStyle.billDiv}>
                      {/* first bill box */}
                      <div className={contactIntroStyle.billBox}>
                        {!billPic1 && (
                          <>
                            <img
                              className={contactIntroStyle.addButton}
                              style={{ display: billPic1 ? "none" : "" }}
                              src={fileAdd}
                              alt="add button pic"
                              onClick={() =>
                                document.getElementById("fileInput1").click()
                              }
                            />
                          </>
                        )}

                        {billPic1 && (
                          <>
                            <img
                              className={contactIntroStyle.fileImage}
                              src={billPic1}
                              alt="bill front"
                            />
                            <div
                              className={contactIntroStyle.dltButton}
                              onClick={() => handleFileRemoval(setBillPic1)}
                            >
                              <img src={fileClose} alt="file close button" />
                            </div>
                          </>
                        )}
                      </div>

                      {/* second bill box */}
                      <div className={contactIntroStyle.billBox}>
                        {!billPic2 && (
                          <>
                            <img
                              className={contactIntroStyle.addButton}
                              style={{ display: billPic2 ? "none" : "" }}
                              src={fileAdd}
                              alt="add button pic"
                              onClick={() =>
                                document.getElementById("fileInput2").click()
                              }
                            />
                          </>
                        )}

                        {billPic2 && (
                          <>
                            <img
                              className={contactIntroStyle.fileImage}
                              src={billPic2}
                              alt="bill back"
                            />
                            <div
                              className={contactIntroStyle.dltButton}
                              onClick={() => handleFileRemoval(setBillPic2)}
                            >
                              <img src={fileClose} alt="file close button" />
                            </div>
                          </>
                        )}
                      </div>

                      {file1Error || file2Error ? (
                        <>
                          <p className={contactIntroStyle.billErrorMsg}>
                            Upload all bills!!{" "}
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    {/* the file input ends */}

                    <input
                      required
                      type="text"
                      className={`form-control border-0 border-bottom ${contactIntroStyle.formFields}`}
                      placeholder="Google Location"
                      name="googleLocation"
                      value={formTwoData?.googleLocation}
                      onChange={handleTwoChange}
                    />

                    <select
                      name="typeOfArea"
                      onChange={handleTwoChange}
                      value={formTwoData?.typeOfArea}
                      className={`form-select border-0 border-bottom ${contactIntroStyle.formFields}`}
                      required
                    >
                      <option value="">
                        {" "}
                        Type of Area for Solar Installation{" "}
                      </option>
                      <option value="Rcc">Rcc</option>
                      <option value="Shed">GI Shed</option>
                      <option value="groundMount">Ground Mount</option>
                      <option value="solarRoof">Solar Roof</option>
                      <option value="elevatedRCC">Elevated RCC</option>
                      <option value="floating">Floating</option>
                    </select>

                    <div className="row">
                      <div className="col-12">
                        <button
                          disabled={btnDisabled}
                          className={contactIntroStyle.submitButton}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactIntro;
